<template>
  <svg
  xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="var(--text-link)" class="nav-link-icon"
  >
    <path
      d="M4 9C3.44772 9 3 9.44772 3 10V24V26C3 26.5523 3.44772 27 4 27H6H20C20.5523 27 21 26.5523 21 26V24C21 23.4477 20.5523 23 20 23H7V10C7 9.44772 6.55228 9 6 9H4Z"
    />
    <path
      d="M15 4C15 3.44772 15.4477 3 16 3H25H26C26.5522 3 27 3.44772 27 4V5V14C27 14.5523 26.5522 15 26 15H25C24.4477 15 24 14.5523 24 14V8.65685L15.2207 17.4361C14.8302 17.8266 14.197 17.8266 13.8065 17.4361L12.3923 16.0219C12.0017 15.6314 12.0017 14.9982 12.3923 14.6077L21 6H16C15.4477 6 15 5.55228 15 5V4Z"
    />
  </svg>
</template>
